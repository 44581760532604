import { useState } from "react";
import { Button, IconButton, Slide, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MdOutlineKeyboardDoubleArrowUp } from "react-icons/md";
import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";
import { GetLabel } from "../languages/i18n";
import DynamicHeroIcon from "../components/heroicons/hicon";
// import { DynamicHeroIcon } from "./DynamicHeroIcon"; // Importa l'icona dinamica

const MobileCart = ({
  cartItems = [],
  total,
  isLoading,
  CreateTempBooking,
  formatDate,
  language,
  removeItemCart,
  fixedCosts,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleCart = () => {
    setIsExpanded(!isExpanded);
  };
  if (!cartItems || cartItems.length === 0) {
    return null;
  }

  return (
    <div style={{ position: "relative", zIndex: 1000 }}>
      {/* Contenitore espandibile del carrello */}
      <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          height: isExpanded ? "50vh" : "131px",
          backgroundColor: "#fff",
          boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px 10px 0 0",
          overflow: "hidden",
          transition: "height 0.3s ease",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Barra di trascinamento fissa */}
        <div
          onClick={toggleCart}
          style={{
            textAlign: "center",
            padding: "10px",
            cursor: "pointer",
            backgroundColor: "#fff",
            paddingBottom: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontSize: "12px",
                color: "#333",
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              {GetLabel(language, "LABEL_RIEPILOGO")}
              {isExpanded ? (
                <MdOutlineKeyboardDoubleArrowDown />
              ) : (
                <MdOutlineKeyboardDoubleArrowUp />
              )}
            </p>
          </div>
        </div>

        {/* Dettagli delle camere, con scroll attivato */}
        <div
          style={{
            flex: 1,
            padding: "0px 20px",
            overflowY: "auto",
          }}
        >
          {cartItems && cartItems.length > 0 ? (
            cartItems.map((item, index) => (
              // <div
              //   key={index}
              //   style={{
              //     display: "flex",
              //     flexDirection: "column",
              //     marginBottom: "15px",
              //     padding: "10px",
              //     borderRadius: "8px",
              //     backgroundColor: "#f9f9f9",
              //     paddingTop: "0px",
              //   }}
              // >
              //   <h4
              //     style={{ fontSize: "18px", margin: "0 0 5px", color: "#333" }}
              //   >
              //     {item.roomName}
              //   </h4>
              //   <div
              //     style={{
              //       display: "flex",
              //       alignItems: "center",
              //       color: "grey",
              //       fontSize: "14px",
              //       marginBottom: "5px",
              //     }}
              //   >
              //     <Tooltip title="Adulti">
              //       <div
              //         style={{
              //           display: "flex",
              //           alignItems: "center",
              //           marginRight: "10px",
              //         }}
              //       >
              //         <FontAwesomeIcon
              //           icon="male"
              //           style={{ marginRight: "5px" }}
              //         />
              //         <span>x {item.guests}</span>
              //       </div>
              //     </Tooltip>
              //     <Tooltip title="Bambini">
              //       <div
              //         style={{
              //           display: "flex",
              //           alignItems: "center",
              //           marginRight: "10px",
              //         }}
              //       >
              //         <FontAwesomeIcon
              //           icon="child"
              //           style={{ marginRight: "5px" }}
              //         />
              //         <span>x {item.childs}</span>
              //       </div>
              //     </Tooltip>
              //   </div>
              //   <div style={{ fontSize: "14px", color: "#555" }}>
              //     {GetLabel(language, "LABEL_FROM_DAY_SHORT")}{" "}
              //     {formatDate(item.start_date)}{" "}
              //     {GetLabel(language, "LABEL_TO_DAY")}{" "}
              //     {formatDate(item.end_date)}
              //   </div>
              //   <div
              //     style={{
              //       fontSize: "20px",
              //       color: "#333",
              //       marginTop: "5px",
              //     }}
              //   >
              //     € {parseFloat(item.price).toFixed(2)}
              //   </div>
              // </div>
              <>
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "15px",
                    borderRadius: "8px",
                    backgroundColor: "#f9f9f9",
                    paddingTop: "0px",
                    position: "relative", // Per posizionare l'icona
                  }}
                >
                  {/* Icona in alto a destra */}
                  <IconButton
                    sx={{
                      position: "absolute",
                      right: "10px",
                      padding: "0 !important",
                      top: "5px",
                    }}
                    onClick={() => removeItemCart(item)}
                  >
                    <DynamicHeroIcon
                      icon={"TrashIcon"}
                      className="input-icon"
                      style={{ width: "18px" }}
                    ></DynamicHeroIcon>
                  </IconButton>

                  {/* Contenitore principale con il prezzo a destra */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between", // Sposta il prezzo a destra
                      alignItems: "center", // Allinea verticalmente
                    }}
                  >
                    {/* Colonna sinistra */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                      }}
                    >
                      {/* Nome della stanza */}
                      <h4
                        style={{
                          fontSize: "17px",
                          margin: "0",
                          marginTop: "5px",
                          color: "#333",
                          fontWeight: "600",
                        }}
                      >
                        {item.roomName}
                      </h4>

                      {/* Adulti e bambini */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: "grey",
                          fontSize: "12px",
                          marginBottom: "5px",
                        }}
                      >
                        <Tooltip title="Adulti">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "10px",
                            }}
                          >
                            <FontAwesomeIcon
                              icon="male"
                              style={{ marginRight: "5px" }}
                            />
                            <span>x {item.guests}</span>
                          </div>
                        </Tooltip>
                        <Tooltip title="Bambini">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "10px",
                            }}
                          >
                            <FontAwesomeIcon
                              icon="child"
                              style={{ marginRight: "5px" }}
                            />
                            <span>x {item.childs}</span>
                          </div>
                        </Tooltip>
                        {item.isNotRefundable && (
                          <div
                            className="w-fit rounded-full bg-purple-500 text-white font-semibold"
                            style={{
                              fontSize: "11px",
                              paddingTop: "2px",
                              paddingBottom: "2px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {GetLabel(language, "LABEL_NOREFUNDABLE")}
                          </div>
                        )}
                      </div>

                      {/* Date */}
                      <div style={{ fontSize: "12px", color: "#555" }}>
                        {GetLabel(language, "LABEL_FROM_DAY_SHORT")}{" "}
                        {formatDate(item.start_date)}{" "}
                        {GetLabel(language, "LABEL_TO_DAY")}{" "}
                        {formatDate(item.end_date)}
                      </div>
                    </div>

                    {/* Prezzo */}
                    <div
                      style={{
                        fontSize: "16px",
                        color: "#333",
                        textAlign: "right", // Facoltativo, per allineare visivamente
                        fontWeight: "600",
                        marginTop: "10px",
                      }}
                    >
                      € {parseFloat(item.price).toFixed(2)}
                    </div>
                  </div>
                </div>
                {item.fixedCosts &&
                  item.fixedCosts.map((cost, costIndex) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <h4
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#333",
                          }}
                        >
                          {cost.name}
                        </h4>
                        <p
                          style={{
                            margin: "0 0 10px",
                            fontSize: "12px",
                            color: "#555",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: cost.description,
                          }}
                        ></p>
                      </div>
                      <h2
                        style={{
                          margin: "0",
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "#333",
                        }}
                      >
                        € {parseFloat(cost.calculatedPrice).toFixed(2)}
                      </h2>
                    </div>
                  ))}
                {item.isNotRefundable && (
                  <p
                    className=" text-gray-600"
                    style={{
                      fontSize: "12px",
                      paddingBottom: "8px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      {GetLabel(
                        language,
                        "TITLE_CANCELLATION_POLICY_NOREFUNDABLE"
                      )}
                    </span>
                    : <br></br>{" "}
                    {GetLabel(
                      language,
                      "LABEL_CANCELLATION_POLICY_NOREFUNDABLE"
                    )}
                  </p>
                )}
              </>
            ))
          ) : (
            <p style={{ textAlign: "center", color: "grey" }}>
              Il carrello è vuoto
            </p>
          )}
        </div>

        {/* Totale e pulsante "Vai al pagamento" fissati in basso */}
        <div
          style={{
            backgroundColor: "#fff",
          }}
        >
          <h4
            style={{
              fontSize: "18px",
              margin: 0,
              color: "#333",
              textAlign: "center",
              marginBottom: "6px",
              paddingTop: "5px",
            }}
          >
            Totale <b>€ {parseFloat(total).toFixed(2)}</b>
          </h4>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={{
              fontWeight: "bold",
              height: "60px",
              borderRadius: "0",
              fontSize: "18px",
              paddingTop: "13px",
              paddingBottom: "13px",
            }}
            loading={isLoading}
            onClick={CreateTempBooking}
          >
            Vai al pagamento
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MobileCart;
