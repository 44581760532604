import { Button, CircularProgress, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import structureService from "../../services/structure.service";
import { GetLabel } from "../../languages/i18n";
import cloneDeep from "lodash/cloneDeep";
import { MdOutlineDoNotDisturb } from "react-icons/md";
import Typography from "@mui/material/Typography";

function RoomOptions({
  open,
  uuid,
  language,
  idCategory,
  priceRoom,
  setPriceRoom,
  rooms,
  addCart,
  setOpen,
}) {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({});
  const [price, setPrice] = useState(0);

  const SaveRoom = (option) => {
    setOpen(false);
    let r = cloneDeep(rooms[0]);
    addCart(
      {
        ...r,
        isNotRefundable: false,
      },
      option,
      false
    );
  };

  return (
    <div
      className={`bg-white rounded-b-lg p-6 mt-1 flex items-center justify-center ${
        open ? "animate__animated animate__fadeInDown" : "hidden"
      }`}
    >
      <div className="grid grid-cols-12 w-full">
        <div className="flex flex-col gap-4 col-span-12">
          {rooms[0]?.roomOptions?.map((option, index) => (
            <div
              key={index}
              className="grid grid-cols-12 w-full border-b pb-4  last:border-none last:pb-0 last:mb-0"
            >
              <div className="flex flex-col gap-2 col-span-12 md:col-span-8">
                <Typography
                  variant="h3"
                  gutterBottom
                  component="div"
                  className="room-name-option"
                  sx={{
                    marginLeft: { xs: "0" },
                    marginTop: {
                      sm: "0 !important",
                      xs: "10px !important",
                    },
                  }}
                >
                  {option.optionName}
                </Typography>
                <div className="flex flex-row gap-3">
                  <Typography variant="body2" color="text.secondary">
                    {GetLabel(language, "LABEL_SEARCH_ADULTI")} :{" "}
                    {option.adults} {GetLabel(language, "LABEL_SEARCH_BAMBINI")}
                    : {option.children}
                  </Typography>
                  {option.isNotRefundable && (
                    <div className="w-fit text-xs py-1 px-3 rounded-full bg-purple-500 text-white font-semibold">
                      {GetLabel(language, "LABEL_NOREFUNDABLE")}
                    </div>
                  )}
                </div>
                <p
                  className="text-sm text-gray-600"
                  dangerouslySetInnerHTML={{
                    __html: option.optionDescription,
                  }}
                ></p>
                <p className="text-sm text-gray-500 italic">
                  {GetLabel(language, "ROOM_TREATMENT")} {option.treatment}
                </p>
              </div>

              <div className="col-span-12 md:col-span-4 flex flex-col md:flex-row md:justify-between gap-4">
                {/* Prezzo */}
                <div className="flex justify-end md:justify-start">
                  <h2 className="text-2xl font-bold text-green-500 whitespace-nowrap">
                    {option.price?.toFixed(2)} &euro;
                  </h2>
                </div>

                {/* Pulsanti */}
                <div className="flex flex-row md:flex-col gap-2  md:justify-start">
                  <Button
                    variant="contained"
                    onClick={() => {
                      let roomToAdd = { ...rooms[0] };
                      roomToAdd.price = option.price;
                      roomToAdd.derivedName = option.derivedName;
                      roomToAdd.isNotRefundable = option.isNotRefundable;
                      addCart(roomToAdd, option, true, true);
                    }}
                    sx={{
                      borderRadius: "5px",
                      fontWeight: "600",
                      minWidth: "155px",
                    }}
                    color="primary"
                    className="w-full"
                  >
                    {GetLabel(language, "LABEL_BOOK_NOW")}
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      fontSize: "12px !important",
                      fontWeight: "600 !important",
                      minWidth: "155px",
                    }}
                    onClick={() => SaveRoom(option)}
                    color="secondary"
                    className="w-full"
                  >
                    {GetLabel(language, "LABEL_SAVE_ROOM")}
                  </Button>
                </div>
              </div>

              {/* <div className="col-span-12 md:col-span-4 flex justify-between items-start gap-4">
                <div className="flex items-center">
                  <h2 className="text-2xl font-bold text-green-500 whitespace-nowrap">
                    {option.price?.toFixed(2)} &euro;
                  </h2>
                </div>
                <div className="flex flex-col gap-2">
                  <Button
                    variant="contained"
                    onClick={() => {
                      let roomToAdd = { ...rooms[0] };
                      roomToAdd.price = option.price;
                      roomToAdd.derivedName = option.derivedName;
                      roomToAdd.isNotRefundable = option.isNotRefundable;
                      addCart(roomToAdd, option, true, true);
                    }}
                    sx={{
                      borderRadius: "5px",
                      fontWeight: "600",
                      minWidth: "150px",
                    }}
                    color="primary"
                  >
                    {GetLabel(language, "LABEL_BOOK_NOW")}
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      fontSize: "14px !important",
                      fontWeight: "500 !important",
                      minWidth: "150px",
                    }}
                    onClick={() => SaveRoom(option)}
                    color="secondary"
                  >
                    {GetLabel(language, "LABEL_SAVE_ROOM")}
                  </Button>
                </div>
              </div> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default RoomOptions;
