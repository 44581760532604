import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DynamicHeroIcon from "../heroicons/hicon";
import CircularProgress from "@mui/material/CircularProgress";
import { GetLabel } from "../../languages/i18n";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import Language from "../language/language";
import LanguageDrawer from "../language/languageDrawer";
import { colors } from "@mui/material";
import { CloseIcon } from "yet-another-react-lightbox";

export default function HorizontalBar(props) {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <div key={props.uuid}>
      <Grid
        container
        className="horizontal-bar"
        sx={{
          paddingLeft: { sm: "10px", xs: "10px" },
          display: { xs: "none", md: "flex" }, // Nascosto su mobile
        }}
      >
        <Grid
          item
          sm={6}
          xs={12}
          sx={{
            mt: { xs: 1, sm: 0 },
            justifyContent: { xs: "center", sm: "left" },
          }}
          className="structure-details"
        >
          <Grid container>
            <Grid
              item
              sm={2}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: { xs: "10px", sm: 0 },
              }}
            >
              <img
                src={"https://www.idaweb.eu" + props.structure_logo}
                alt="Logo"
                style={{
                  width: "128px",
                  maxHeight: "64px",
                  marginRight: "10px",
                  objectFit: "cover",
                }}
              />
              {/* <img src="https://bookinghotel.qcterme.com/Repository/2489//Loghi/BormioBV1.png" style={{width:'128px'}}></img> */}
            </Grid>
            <Grid
              item
              sm={10}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "left" },
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.2em",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  paddingLeft: "15px",
                }}
              >
                {props.isStructureLoading ? (
                  <CircularProgress className="loading-indicator" />
                ) : (
                  <div>
                    {props.isStructureLoading ? (
                      <CircularProgress className="loading-indicator" />
                    ) : (
                      <h2 style={{ fontSize: "18px", color: "#222" }}>
                        {props.structure_name}
                      </h2>
                    )}
                    <p
                      style={{
                        fontSize: "14px",
                        color: "gray",
                        fontWeight: "500",
                      }}
                    >
                      {props.isStructureLoading ? (
                        <CircularProgress className="loading-indicator" />
                      ) : (
                        props.structure_address
                      )}{" "}
                      |{" "}
                      {props.isStructureLoading ? (
                        <CircularProgress className="loading-indicator" />
                      ) : (
                        props.structure_location
                      )}
                    </p>
                  </div>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
          className="buttons"
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "right" },
            alignItems: "center",
            marginTop: { xs: "10px", sm: "0" },
          }}
        >
          <Grid container spacing={0} sx={{ justifyContent: "end" }}>
            <Grid
              item
              sm={5}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "end" },
              }}
            >
              <Button
                variant="text"
                className="action-button"
                onClick={() => {
                  window.location.href = "mailto:" + props.structure_email;
                }}
              >
                <DynamicHeroIcon
                  icon={"MailIcon"}
                  className="button-icon"
                ></DynamicHeroIcon>
                {props.isStructureLoading ? (
                  <CircularProgress className="loading-indicator" />
                ) : (
                  props.structure_email
                )}
              </Button>
            </Grid>
            <Grid
              item
              sm={3}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "center" },
              }}
            >
              <Button variant="text" className="action-button">
                <DynamicHeroIcon
                  icon={"PhoneIcon"}
                  className="button-icon"
                ></DynamicHeroIcon>
                {props.isStructureLoading ? (
                  <CircularProgress className="loading-indicator" />
                ) : (
                  props.structure_phone
                )}
              </Button>
            </Grid>
            <Grid
              item
              sm={3}
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="text"
                className="action-button"
                onClick={() =>
                  window.location.replace(
                    " https://www.idaweb.eu/be/be_reservation/LanguageBE/IT/" +
                      props.uuid
                  )
                }
              >
                <DynamicHeroIcon
                  icon={"BookmarkIcon"}
                  className="button-icon"
                ></DynamicHeroIcon>
                {GetLabel(props.language, "LABEL_GESTISCI_PRENOTAZIONE")}
              </Button>
            </Grid>
            <Grid
              item
              sm={1}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "right" },
              }}
            >
              <Language
                className="language-box"
                setLanguage={props.setLanguage}
                language={props.language}
              ></Language>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          backgroundColor: "#fff",
          boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
          position: "fixed", // La barra resta fissa in alto
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1301, // Sopra il drawer
          // height: "70px", // Imposta l'altezza
          paddingTop: "15px",
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
      >
        <Box
          sx={{
            fontWeight: "bold",
            fontSize: "1.2em",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {props.isStructureLoading ? (
            <CircularProgress className="loading-indicator" />
          ) : (
            <div>
              <h2 style={{ fontSize: "16px", color: "#222" }}>
                {props.structure_name}
              </h2>
              <p style={{ fontSize: "11px", color: "gray" }}>
                {props.structure_address} | {props.structure_location}
              </p>
            </div>
          )}
        </Box>

        <IconButton
          edge="end"
          color="inherit"
          onClick={toggleDrawer(!drawerOpen)}
          sx={{
            color: "black",
          }}
        >
          {drawerOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      </Box>
      <Drawer
        anchor="top"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%", // Occupa tutta la larghezza
            height: "min-content", // Occupa metà schermo
            marginTop: "65px", // Inizia sotto la barra orizzontale
            padding: "10px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "2px",
            alignItems: "flex-start",
            // paddingTop: "5px",
          }}
        >
          <Button
            variant="text"
            className="action-button"
            onClick={() =>
              (window.location.href = "mailto:" + props.structure_email)
            }
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              fontSize: "12px",
              color: "#333",
              textTransform: "none", // Disabilita la maiuscolizzazione automatica del testo
            }}
          >
            <DynamicHeroIcon
              icon={"MailIcon"}
              className="button-icon"
              style={{ width: "20px", height: "20px", color: "grey" }}
            />
            {props.structure_email}
          </Button>
          <Button
            variant="text"
            className="action-button"
            onClick={() =>
              (window.location.href = "tel:" + props.structure_phone)
            }
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              fontSize: "12px",
              color: "#333",
            }}
          >
            <DynamicHeroIcon
              icon={"PhoneIcon"}
              className="button-icon"
              style={{ width: "20px", height: "20px", color: "grey" }}
            />
            {props.structure_phone}
          </Button>
          <Button
            variant="text"
            className="action-button"
            onClick={() =>
              window.location.replace(
                "https://www.idaweb.eu/be/be_reservation/LanguageBE/IT/" +
                  props.uuid
              )
            }
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              fontSize: "12px",
              color: "#333",
            }}
          >
            <DynamicHeroIcon
              icon={"BookmarkIcon"}
              className="button-icon"
              style={{ width: "20px", height: "20px", color: "grey" }}
            />
            {GetLabel(props.language, "LABEL_GESTISCI_PRENOTAZIONE")}
          </Button>
          <LanguageDrawer
            className="language-box"
            setLanguage={props.setLanguage}
            language={props.language}
          />

          {/* Button for managing reservation */}
        </Box>
      </Drawer>
    </div>
  );
}
