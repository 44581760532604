export const ES_labels = [
  {
    label: "LABEL_GESTISCI_PRENOTAZIONE",
    text: "Gestionar reserva",
  },
  {
    label: "LABEL_ROOM_NOT",
    text: "No ",
  },
  {
    label: "LABEL_ROOM_REFUNDABLE",
    text: "Reembolsable",
  },
  {
    label: "LABEL_SEARCH_CAMERE",
    text: "Habitaciones",
  },
  {
    label: "LABEL_SEARCH_ADULTI",
    text: "Adultos",
  },
  {
    label: "LABEL_SEARCH_BAMBINI",
    text: "Niños",
  },
  {
    label: "LABEL_SEARCH_ROOM_PLACEHOLDER",
    text: "Habitación",
  },
  {
    label: "LABEL_SEARCH_BUTTON",
    text: "Verificar disponibilidad",
  },
  {
    label: "LABEL_SEARCH_BUTTON_SEARCHING",
    text: "Buscando ...",
  },
  {
    label: "LABEL_SEARCH_COUPON_CODE",
    text: "Tengo un código de descuento",
  },
  {
    label: "LABEL_SEARCH_PLACEHOLDER_COUPON",
    text: "Introduce tu código aquí",
  },
  {
    label: "LABEL_CALENDAR_ARRIVO",
    text: "Llegada",
  },
  {
    label: "LABEL_CALENDAR_PARTENZA",
    text: "Salida",
  },
  {
    label: "LABEL_CALENDAR_NOTTI",
    text: "Noches",
  },
  {
    label: "LABEL_ROOM_DESCR_SHOW_MORE",
    text: "... Leer más",
  },
  {
    label: "LABEL_ROOM_DESCR_SHOW_LESS",
    text: "... Mostrar menos",
  },
  {
    label: "LABEL_ROOM_GUESTS",
    text: "Huéspedes",
  },
  {
    label: "LABEL_ROOM_ADDED_CART",
    text: "Tu habitación ha sido añadida al carrito",
  },
  {
    label: "LABEL_FROM_DAY",
    text: "desde el día",
  },
  {
    label: "LABEL_FROM_DAY_SHORT",
    text: "Desde",
  },
  {
    label: "LABEL_TO_DAY",
    text: "hasta",
  },
  {
    label: "LABEL_ROOM_REMOVED",
    text: "ya no está disponible y ha sido eliminada",
  },
  {
    label: "LABEL_REMOVE_FROM_CART",
    text: "Eliminar del carrito",
  },
  {
    label: "LABEL_OPS_EMPTY",
    text: "Oops.. aquí está vacío",
  },
  {
    label: "LABEL_ADD_ROOM_PLACEHOLDER",
    text: "Añade una habitación buscando la que mejor se adapte a ti...",
  },
  {
    label: "LABEL_TOTAL",
    text: "Total",
  },
  {
    label: "LABEL_GOTO_PAYMENT",
    text: "Ir al pago",
  },
  {
    label: "LABEL_NO_ROOMS_FOUND",
    text: "Lo sentimos, no tenemos habitaciones disponibles para las fechas buscadas.",
  },
  {
    label: "LABEL_NO_ROOMS_GG_MIN_1",
    text: "Lo sentimos, para las fechas seleccionadas el alojamiento requiere una estancia mínima de",
  },
  {
    label: "LABEL_NO_ROOMS_GG_MIN_2",
    text: " noches.",
  },
  {
    label: "LABEL_NO_ROOMS_LAST_MINUTE",
    text: "Lo sentimos, no aceptamos reservas de última hora. Las reservas para hoy están cerradas.",
  },
  {
    label: "LABEL_CONTACT_US",
    text: "Por favor, intente buscar de nuevo o contáctenos en los siguientes contactos:",
  },
  {
    label: "LABEL_SERVICES",
    text: "Servicios disponibles",
  },
  {
    label: "LABEL_NO_SERVICE",
    text: "No hay servicios disponibles",
  },
  {
    label: "LABEL_TAX_STAY",
    text: "Impuesto de estancia",
  },
  {
    label: "LABEL_CURRENCY_LONG",
    text: "EUR",
  },
  {
    label: "LABEL_STAY_TAX_DESCR",
    text: "por noche por huésped a pagar en el lugar.",
  },
  {
    label: "LABEL_NO_TAX_TO_PAY",
    text: "No hay impuesto de estancia que pagar.",
  },
  {
    label: "LABEL_DESCRIPTION",
    text: "Descripción",
  },
  {
    label: "CIN_CODE",
    text: "Código CIN: ",
  },
  {
    label: "LABEL_BOOKING_TERMS",
    text: "Condiciones de reserva y políticas de cancelación",
  },
  {
    label: "LABLE_OCCUPAZIONE_INFERIORE_TITLE",
    text: "Ocupación inferior a la solicitada",
  },
  {
    label: "LABLE_OCCUPAZIONE_INFERIORE_DESCR",
    text: "La ocupación de las habitaciones seleccionadas es inferior al número de personas solicitado. ¿Proceder con el pago de todos modos?",
  },
  {
    label: "LABLE_OCCUPAZIONE_INFERIORE_YES",
    text: "Sí, proceder",
  },
  {
    label: "LABEL_FIXED_COST",
    text: "Costos fijos",
  },
  {
    label: "LABEL_FIXED_COST_INCLUDED",
    text: " (incluidos los costos fijos)",
  },
  {
    label: "LABEL_TOTAL_BOOKING_ROOM",
    text: "Total de la estancia",
  },
  {
    label: "TOOLTIP_ROOM_NOT_REFAUNDABLE",
    text: "Esta tarifa ofrece un precio ventajoso, pero no permite reembolsos en caso de cancelación, modificación o no presentación.",
  },
  {
    label: "LABEL_PRICE",
    text: "Precio",
  },
  {
    label: "LABEL_ADD_TO_CART",
    text: "Añadir al carrito",
  },
  {
    label: "LABEL_BOOK_NOW",
    text: "¡Reserva ahora!",
  },
  {
    label: "LABEL_CHECK_ROOM_CART",
    text: "¿Quieres mantener las habitaciones en el carrito?",
  },
  {
    label: "LABEL_CHECK_ROOM_CART_YES",
    text: "Sí, guardar las habitaciones",
  },
  {
    label: "LABEL_CHECK_ROOM_CART_NO",
    text: "No",
  },
  {
    label: "LABEL_MINIMO_NOTTI",
    text: "Número mínimo de noches requerido",
  },
  {
    label: "LABEL_RESTRIZIONI",
    text: "Llegada y salida no permitidas",
  },
  {
    label: "LABEL_PERMESSO_ARRIVO",
    text: "Solo se permite la llegada",
  },
  {
    label: "LABEL_PERMESSO_PARTENZA",
    text: "Solo se permite la salida",
  },
  {
    label: "LABEL_RIEPILOGO",
    text: "Resumen de la reserva",
  },
  {
    label: "ROOM_SERVICES",
    text: "Servicios disponibles:",
  },
  {
    label: "NO_SERVICES",
    text: "No hay servicios disponibles",
  },
  {
    label: "PRICE_FROM",
    text: "Desde",
  },
  {
    label: "BUTTON_ROOM_INFO_BOOK",
    text: "Información y reserva",
  },
  {
    label: "LABEL_ROOM_REMOVED_APARTMENT",
    text: "La habitación / apartamento",
  },
  {
    label: "LABEL_RETURN_TO_SEARCH",
    text: "Volver a la búsqueda",
  },
  {
    label: "LABEL_YEARS",
    text: "años",
  },
  {
    label: "LABEL_UNAVAILABLE",
    text: "No disponible",
  },
  {
    label: "LABEL_ROOM_SEARCHED_FOR",
    text: "La habitación buscada en la fecha del",
  },
  {
    label: "LABEL_NO_MORE_AVAILABLE",
    text: "ya no está disponible, por favor realice una nueva búsqueda.",
  },
  {
    label: "LABEL_PRICE_BOOKING",
    text: "Precio del alojamiento:",
  },
  {
    label: "LABEL_DISCOUNT_CODE",
    text: "Código de descuento",
  },
  {
    label: "LABEL_DISCOUNT_AMOUNT",
    text: "Descuento aplicado",
  },
  {
    label: "LABEL_YOUR_CART",
    text: "Tu carrito",
  },
  {
    label: "LABEL_TAX_PAY_EXCLUDED",
    text: "El impuesto de estancia no está incluido en el precio",
  },
  {
    label: "SUBTITLE_EXTRAS",
    text: "Haz que tu estancia sea inolvidable seleccionando tus experiencias favoritas",
  },
  {
    label: "LABEL_EXTRA_TOTAL",
    text: "Total incluyendo servicios adicionales",
  },
  {
    label: "LABEL_NO_ROOM_PAY",
    text: "No es posible continuar con la compra ya que no hay habitaciones o unidades habitacionales disponibles para la reserva.",
  },
  {
    label: "LABEL_NO_SEARCH_COMBINATIONS",
    text: "No se encontraron combinaciones para la búsqueda realizada. Por favor, introduzca las fechas de la estancia, el número de personas y las habitaciones requeridas en el cuadro de la izquierda.",
  },
  {
    label: "LABEL_PAYMENT_INSERT_YOUR_DATA",
    text: "Introduce tus datos",
  },
  {
    label: "LABEL_PAYMENT_NO_MANDATORY",
    text: "Los campos marcados con un asterisco son obligatorios",
  },
  {
    label: "LABEL_PAYMENT_FORM_NAME",
    text: "Nombre *",
  },
  {
    label: "LABEL_PAYMENT_FORM_ERROR_NAME",
    text: "Por favor introduce un nombre válido",
  },
  {
    label: "LABEL_PAYMENT_FORM_ERROR_SURNAME",
    text: "Por favor introduce un apellido válido",
  },
  {
    label: "LABEL_PAYMENT_FORM_ERROR_EMAIL",
    text: "Por favor introduce un correo electrónico válido",
  },
  {
    label: "LABEL_PAYMENT_FORM_ERROR_PHONE",
    text: "Por favor introduce un número de teléfono válido",
  },
  {
    label: "LABEL_PAYMENT_FORM_SURNAME",
    text: "Apellido *",
  },
  {
    label: "LABEL_PAYMENT_FORM_EMAIL",
    text: "Correo electrónico *",
  },
  {
    label: "LABEL_PAYMENT_FORM_CONFIRM_EMAIL",
    text: "Confirma tu correo electrónico *",
  },
  {
    label: "LABEL_PAYMENT_FORM_PHONE",
    text: "Número de móvil *",
  },
  {
    label: "LABEL_PAYMENT_CONFIRM_FORM_PHONE",
    text: "Confirma tu número de móvil *",
  },
  {
    label: "LABEL_PAYMENT_FORM_COUNTRY",
    text: "País",
  },
  {
    label: "LABEL_PAYMENT_FORM_CITY",
    text: "Ciudad",
  },
  {
    label: "LABEL_PAYMENT_FORM_ZIP_CODE",
    text: "Código postal",
  },
  {
    label: "LABEL_PAYMENT_FORM_ADDRESS",
    text: "Dirección",
  },
  {
    label: "LABEL_PAYMENT_FORM_NOTES",
    text: "Notas / Información adicional (opcional)",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_METHODS",
    text: "Métodos de pago",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_METHODS_1",
    text: "Selecciona un método de pago para la reserva",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_METHODS_2",
    text: "Tipo de pago",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_CARD",
    text: "Tarjeta de crédito",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_TRANSFER",
    text: "Transferencia bancaria",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_CARD_SUBTITLE_1",
    text: "Tarjeta de crédito como garantía - sitio seguro",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_CARD_SUBTITLE_2",
    text: "Los datos de tu tarjeta de crédito son solicitados como garantía de la reserva y de posibles extras.",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_CARD_SUBTITLE_3",
    text: "se reserva el derecho de retener temporalmente un monto equivalente a la primera noche antes de tu llegada en forma de pre-autorización de la tarjeta de crédito proporcionada. Recomendamos usar una tarjeta de crédito en lugar de una tarjeta de débito o prepago para reducir los tiempos de gestión bancaria de la liberación de la posible pre-autorización.",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_1",
    text: "He leído y acepto las condiciones de protección de datos personales indicadas",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_2",
    text: "en la declaración de privacidad",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_3",
    text: "Al confirmar esta reserva también acepto los",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_4",
    text: "términos generales",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_5",
    text: " y las condiciones de reserva enumeradas arriba para cada unidad habitacional.",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_6",
    text: "Deseo recibir información sobre ofertas especiales y novedades.",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_7",
    text: "He leído y acepto las condiciones de protección de datos personales indicadas.",
  },
  {
    label: "LABEL_PAYMENT_FORM_BUTTON_1",
    text: "Confirmar reserva",
  },
  {
    label: "LABEL_PAYMENT_FORM_BUTTON_2",
    text: "Pagar en línea",
  },
  {
    label: "LABEL_PAYMENT_FORM_BUTTON_SUBTITLE_1",
    text: "Queda un paso antes de completar el proceso",
  },
  {
    label: "LABEL_PAYMENT_FORM_ONE_MOMENT",
    text: "Tomará solo un momento",
  },
  {
    label: "BT_TITLE",
    text: "Método de pago: Transferencia bancaria",
  },
  {
    label: "BT_SUBTITLE",
    text: "Instrucciones:",
  },
  {
    label: "BT_TEXT1",
    text: "Por favor realiza el pago dentro de los próximos",
  },
  {
    label: "BT_TEXT1_5",
    text: " días. Tan pronto como recibamos la transferencia, te enviaremos una confirmación por correo electrónico con los detalles de tu compra.",
  },
  {
    label: "BT_TEXT2",
    text: "Una vez realizada la transferencia, recuerda guardar una copia del recibo y cargarlo aquí abajo.",
  },
  {
    label: "BT_TEXT3",
    text: "Importante",
  },
  {
    label: "BT_TEXT4",
    text: "No revisamos los créditos diariamente, por lo tanto, no olvides cargar el recibo de la transferencia aquí abajo, de lo contrario la reserva no será válida.",
  },
  {
    label: "BT_TEXT5",
    text: "* Por favor introduce correctamente los datos, especialmente el concepto, para facilitar la gestión de tu pedido",
  },
  {
    label: "BT_FORM_1",
    text: "IBAN:",
  },
  {
    label: "BT_FORM_2",
    text: "Concepto:",
  },
  {
    label: "BT_FORM_3",
    text: "Beneficiario:",
  },
  {
    label: "BT_FORM_4",
    text: "Importe:",
  },
  {
    label: "BT_FORM_5",
    text: "Subir y enviar transferencia",
  },
  {
    label: "BT_PAY_1",
    text: "¡Suelta los archivos aquí!",
  },
  {
    label: "BT_PAY_2",
    text: "Arrastra y suelta tu recibo aquí!",
  },
  {
    label: "BT_PAY_3",
    text: "O haz clic aquí para seleccionar los archivos",
  },
  {
    label: "BT_PAY_4",
    text: "Tu archivo está listo para ser subido.",
  },
  {
    label: "BT_PAY_5",
    text: 'Pulsa "Subir y enviar transferencia" para confirmar la reserva.',
  },
  {
    label: "CARD_FORM_1",
    text: "Número de tarjeta",
  },
  {
    label: "CARD_FORM_2",
    text: "Nombre del titular de la tarjeta",
  },
  {
    label: "CARD_FORM_3",
    text: "Fecha de caducidad",
  },
  {
    label: "CARD_FORM_4",
    text: "Mes",
  },
  {
    label: "CARD_FORM_5",
    text: "Año",
  },
  {
    label: "CARD_FORM_6",
    text: "Confirmar reserva",
  },
  {
    label: "CARD_FORM_7",
    text: "NOMBRE COMPLETO",
  },
  {
    label: "CARD_FORM_8",
    text: "Caducidad",
  },
  {
    label: "SUCCESS_THANK_YOU",
    text: "Gracias",
  },
  {
    label: "SUCCESS_THANK_YOU_1",
    text: "su reserva está confirmada",
  },
  {
    label: "SUCCESS_THANK_YOU_2",
    text: "Se le ha enviado un correo electrónico a la dirección",
  },
  {
    label: "SUCCESS_THANK_YOU_3",
    text: "con todos los detalles.",
  },
  {
    label: "SUCCESS_THANK_YOU_4",
    text: "le espera el día",
  },
  {
    label: "SUCCESS_THANK_YOU_5",
    text: "El pago",
  },
  {
    label: "SUCCESS_THANK_YOU_6",
    text: "será gestionado por",
  },
  {
    label: "SUCCESS_THANK_YOU_7",
    text: "Modifique su reserva o haga una pregunta al alojamiento en unos pocos clics.",
  },
  {
    label: "SUCCESS_THANK_YOU_8",
    text: "Detalles de la reserva",
  },
  {
    label: "SUCCESS_THANK_YOU_9",
    text: "Su reserva",
  },
  {
    label: "SUCCESS_THANK_YOU_10",
    text: "Código de reserva",
  },
  {
    label: "SUCCESS_THANK_YOU_11",
    text: "Pin para modificaciones",
  },
  {
    label: "SUCCESS_THANK_YOU_12",
    text: "Reservado a nombre de",
  },
  {
    label: "SUCCESS_THANK_YOU_13",
    text: "Los extras de su estancia",
  },
  {
    label: "SUCCESS_THANK_YOU_14",
    text: "Cantidad:",
  },
  {
    label: "SUCCESS_THANK_YOU_15",
    text: "Impuestos",
  },
  {
    label: "SUCCESS_THANK_YOU_16",
    text: "A pagar en el alojamiento",
  },
  {
    label: "SUCCESS_THANK_YOU_17",
    text: "El paquete que ha elegido",
  },
  {
    label: "SUCCESS_THANK_YOU_18",
    text: "Ha introducido un código de descuento",
  },
  {
    label: "PAYMENT_LOADING_TEXT_1",
    text: "Estamos contactando al alojamiento ...",
  },
  {
    label: "PAYMENT_LOADING_TEXT_2",
    text: "Guardando su reserva ...",
  },
  {
    label: "PAYMENT_LOADING_TEXT_3",
    text: "Confirmando el estado ...",
  },
  {
    label: "ERROR_TITLE",
    text: "Oops.. algo salió mal",
  },
  {
    label: "ERROR_CODE_5_TEXT",
    text: "Desafortunadamente, una o más habitaciones de su selección ya han sido reservadas. Será redirigido a una nueva búsqueda.",
  },
  {
    label: "ERROR_CODE_GENERIC_TEXT",
    text: "No se pudo crear la reserva. Le recomendamos contactar al alojamiento.",
  },
  {
    label: "LABEL_ERROR_ALERT_1",
    text: "Oops.. La habitación ya está ocupada",
  },
  {
    label: "LABEL_BODY_ERROR_ALERT_1",
    text: "Parece que ya está reservando una habitación idéntica, pero el alojamiento ya no tiene disponibilidad para este tipo. Por favor, realice una nueva búsqueda.",
  },
  {
    label: "LABEL_TITLE_ROOM_ADDED_CART",
    text: "¡La habitación está guardada!",
  },
  {
    label: "LABEL_PRIVACY_POLICY",
    text: "Términos y condiciones de privacidad (POLÍTICA DE PRIVACIDAD)",
  },
  {
    label: "LABEL_BOOKING_TERMS",
    text: "Términos y condiciones de reserva (TÉRMINOS DE RESERVA)",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART1",
    text: "En virtud del artículo 13 del Reglamento (UE) 2016/679 sobre protección de datos personales, le informamos que sus datos personales, proporcionados libremente por usted, serán tratados por F451 S.R.L, en adelante Responsable del tratamiento, principalmente mediante herramientas informáticas para atender su solicitud de suscripción a nuestro boletín automatizado, que le permitirá estar actualizado sobre los servicios que ofrecemos y nuestras actividades promocionales, así como conocer su nivel de satisfacción respecto a los servicios que ha disfrutado, realizar estudios de mercado y enviarle material publicitario.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART2",
    text: "Le recordamos que siempre tendrá la opción de revocar el consentimiento para esta suscripción.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART3",
    text: "Proporcionar sus datos personales es opcional; sin embargo, no hacerlo nos impedirá cumplir con su solicitud y, en consecuencia, inscribirlo en nuestro boletín.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART4",
    text: "Si decide proporcionarnos sus datos, le informamos que estos serán tratados exclusivamente por personal autorizado por el Responsable y se conservarán únicamente durante el tiempo necesario para alcanzar el propósito mencionado anteriormente.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART5",
    text: "Sus datos no serán difundidos en ningún caso, pero podrán ser comunicados a terceros en quienes el Responsable confíe únicamente para garantizar el correcto cumplimiento del propósito mencionado anteriormente.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART6",
    text: "Le recordamos que tiene garantizados los siguientes derechos: derecho de acceso, derecho de rectificación, derecho de supresión, derecho de limitación, derecho de portabilidad, derecho de revocación del consentimiento, derecho de reclamación, derecho de oposición y derecho a no ser objeto de una decisión basada únicamente en el tratamiento automatizado, incluida la elaboración de perfiles, salvo en los casos previstos por el Reglamento. Para ejercer dichos derechos, puede contactar al Responsable del tratamiento escribiendo a F451 S.R.L. - Via Giuseppe Parini 13 o enviando un correo electrónico a sviluppo@idaitalia.com. Sin perjuicio de otros recursos administrativos o judiciales, tiene derecho a presentar una reclamación ante una Autoridad de control si considera que el tratamiento que le concierne viola el Reglamento.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_TITLE",
    text: "Boletín y Marketing",
  },
  {
    label: "ROOM_TREATMENT",
    text: "Tratamiento:",
  },
  {
    label: "LABEL_SHOW_MORE",
    text: "Ver más",
  },
  {
    label: "LABEL_SAVE_ROOM",
    text: "Añadir habitación",
  },
  {
    label: "TITLE_CANCELLATION_POLICY_NOREFUNDABLE",
    text: "Política de cancelación",
  },
  {
    label: "LABEL_CANCELLATION_POLICY_NOREFUNDABLE",
    text: "Cargo total al momento de la reserva sin posibilidad de modificación o cancelación.",
  },
  {
    label: "LABEL_NOREFUNDABLE",
    text: "No reembolsable",
  },
  {
    label: "BUTTON_NOREFUNDABLE",
    text: "No reembolsable",
  },
  {
    label: "PACKET_STARTING",
    text: "Desde",
  },
  {
    label: "PACKET_FOR",
    text: "por",
  },
  {
    label: "PACKET_NIGHTS",
    text: "noches.",
  },
  {
    label: "PACKET_SHOW",
    text: "Ver todos los paquetes",
  },
  {
    label: "PACKET_CHOOSE",
    text: "Elegir paquete",
  },
  {
    label: "PACKET_BACK",
    text: "Volver",
  },
  {
    label: "PACKET_CHOOSE_DATE",
    text: "Elija una fecha:",
  },
  {
    label: "PACKET_VALID_PERIOD",
    text: "Ha seleccionado un período válido",
  },
  {
    label: "PACKET_WRONG_PERIOD",
    text: "Ha seleccionado un período no válido",
  },
  {
    label: "PACKET_SELECT_ROOM",
    text: "Seleccione una habitación",
  },
];
