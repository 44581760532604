import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CircularProgress } from "@mui/material";
import structureService from "../../services/structure.service";
import { GetLabel } from "../../languages/i18n";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  maxHeight: 800,
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
};

export default function Privacy(props) {
  const [privacy, setPrivacy] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (props.open) fetchPrivacy();
  }, [props.open]);

  const handleClose = () => props.setOpen(false);

  const fetchPrivacy = async () => {
    setLoading(true);
    structureService.getPrivacy(`${props.uuid}`, props.language).then(
      (result) => {
        result = result.data;
        if (result) {
          setPrivacy(result[0]);
        }
        setLoading(false);
      },
      (error) => {}
    );
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {GetLabel(props.language, "LABEL_PRIVACY_POLICY")}
          </Typography>
          <Typography className="d-lb" sx={{ mt: 1, mb: 2 }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <span
                dangerouslySetInnerHTML={{ __html: privacy.privacy_policy }}
              />
            )}
          </Typography>

          <Typography id="modal-modal-title" variant="h6" component="h2">
            {GetLabel(props.language, "LABEL_BOOKING_TERMS")}
          </Typography>
          <Typography className="d-lb" sx={{ mt: 1, mb: 2 }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <span
                dangerouslySetInnerHTML={{ __html: privacy.booking_terms }}
              />
            )}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
