import * as React from "react";
import { Navigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import HorizontalBar from "../../components/layout/horizontalbar";
import Room from "../../components/room/room";
import structureService from "../../services/structure.service";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import SkeletonRoom from "../../components/skeleton_room/skeleton-room";
import CircularProgress from "@mui/material/CircularProgress";
import DynamicHeroIcon from "../../components/heroicons/hicon";
import Badge from "@mui/material/Badge";
import Drawer from "@mui/material/Drawer";
import Flickity from "react-flickity-component";
import { Box, IconButton, Tooltip, useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/it";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { GetLabel } from "../../languages/i18n";
import useExternalScripts from "../../components/useExternalScripts";
import Packets from "../../components/packets/Packets";
import "animate.css";
import MobileCart from "../MobileCart";
import MyCalendar from "../MyCalendar";
import { Typography, Button } from "@mui/material";
import { CloseIcon } from "yet-another-react-lightbox";
import AlertAvailability from "../../components/alert_availability/alert_availability";

const urlParams = new URLSearchParams(window.location.search);
function makeid(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
export default function Search() {
  const { json, uuid } = useParams();
  const Swal = require("sweetalert2");
  const [continueToCart, setContinueToCart] = React.useState(false);
  const [rooms, setRooms] = React.useState([]);
  const [isSearching, setSearching] = React.useState(true);
  const [isStructureLoading, setStructureLoading] = React.useState(false);
  const [isOpenCart, setIsOpenCart] = React.useState(false);
  const [structureInfo, setStructureInfo] = React.useState({
    structure_background: "",
    structure_name: "",
    structure_address: "",
    structure_location: "",
    structure_logo: "",
    structure_phone: "",
    structure_email: "",
    uuid: null,
  });
  const [fixedCostsAdded, setFixedCostsAdded] = React.useState(false);
  const [fixedCosts, setFixedCosts] = React.useState([
    {
      insertion_booking_engine: null,
      image: null,
      price_unit: null,
      um: null,
      name: null,
      description: null,
      vat: null,
      is_daily: null,
    },
  ]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [searchBase, setSearchBase] = React.useState({
    date_from: new Date(),
    date_to: new Date(new Date().setDate(new Date().getDate() + 1)),
    promo_code: "",
    rooms: [],
    uuid: "",
    lang: "",
  });
  const [cart, setCart] = React.useState([]);
  const [tax, setTax] = React.useState([]);
  const [dates, setDates] = React.useState({
    start_date: null,
    end_date: null,
  });
  const [isLoadingCart, setIsLoadingCart] = React.useState(false);
  const [removedItems, setRemovedItems] = React.useState([]);
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const [total, setTotal] = React.useState(0);
  const [language, setLanguage] = React.useState("IT");
  const [lastIndex, setLastIndex] = React.useState({
    lastIndex: 0,
    has_more_rooms: false,
    do_new_search: false,
  });
  const [isLoadingMoreRooms, setIsLoadingMoreRooms] = React.useState(false);
  const [promoCode, setPromoCode] = React.useState("");
  React.useEffect(() => {
    if (isStructureLoading === false) {
      if (searchBase.rooms.length > 0) {
        getRooms();
      }
    }
  }, [isStructureLoading]);
  React.useEffect(() => {
    if (isStructureLoading === false) {
      if (searchBase.rooms.length > 0) {
        getRooms(true);
      }
    }
  }, [language]);

  React.useEffect(() => {
    if (lastIndex.do_new_search === true) {
      if (searchBase.rooms.length > 0) {
        getRooms();
      }
    }
  }, [lastIndex.do_new_search]);

  let navigate = useNavigate();
  useExternalScripts(
    "https://cdn-cookieyes.com/client_data/a93ae9b855bbb163b3634c57/script.js"
  );
  React.useEffect(() => {
    if (!searchBase || !searchBase.uuid) {
      return; // Aspetta che searchBase.uuid sia impostato
    }
    setIsLoading(true);
    // Recupera la struttura corrente
    const currentStructureUuid = searchBase.uuid;

    // Recupera il carrello da localStorage
    const savedCart = JSON.parse(localStorage.getItem("cart"));

    if (savedCart && savedCart.length > 0) {
      // Verifica se tutte le camere nel carrello appartengono alla struttura corrente
      const areRoomsFromCurrentStructure = savedCart.every(
        (item) => item.uuid === currentStructureUuid
      );

      if (!areRoomsFromCurrentStructure) {
        // Puoi anche svuotare il carrello o fare altre azioni se necessario
        localStorage.removeItem("cart"); // ad esempio rimuovere il carrello
      }
    }

    setIsLoading(false);
  }, [structureInfo]);
  React.useEffect(() => {
    if (searchBase?.lang) {
      getStructureFixedCosts(uuid);
    }
  }, [searchBase?.lang, uuid]);
  React.useEffect(() => {
    setIsLoading(true);

    if (json) {
      var jj = base64_decode(json);
      var new_json = JSON.parse(jj);
      setSearchBase(new_json);
      getStructureInfo(new_json.uuid);
      if (new_json.lang) setLanguage(new_json.lang.toUpperCase());
    } else {
      if (!uuid)
        window.location.replace("/Home/search1/" + urlParams.get("uuid"));
      setSearchBase({
        date_from: moment().add(1, "days").format("DD/MM/YYYY"),
        date_to: moment().add(2, "days").format("DD/MM/YYYY"),
        promo_code: "",
        rooms: [
          {
            id: "room_0",
            adults: 2,
            childs: [],
          },
        ],
        uuid: uuid,
        lang: "IT",
      });
      getStructureInfo(uuid);
    }

    const old_cart = JSON.parse(localStorage.getItem("cart"));
    if (old_cart) {
      setCart(old_cart);
      setTotal(0);
      var cart_total = 0;
      old_cart.map((item, index) => {
        if (item.price)
          cart_total = parseFloat(cart_total) + parseFloat(item.price);

        if (item.fixedCosts && Array.isArray(item.fixedCosts)) {
          item.fixedCosts.forEach((fixedCost) => {
            if (fixedCost.calculatedPrice) {
              cart_total += parseFloat(fixedCost.calculatedPrice); // Somma ciascun calculatedPrice
            }
          });
        }
      });
      setTotal(cart_total);
    }
  }, []);

  React.useEffect(() => {
    setIsLoading(false);
  }, [searchBase]);

  function formatDate(dateString) {
    if (!dateString) return ""; // Ritorna stringa vuota se la data è null o undefined

    let date;
    // Prova a parsare 'YYYY-MM-DD'
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      date = new Date(dateString);
    }
    // Prova a parsare 'DD/MM/YYYY'
    else if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
      const [day, month, year] = dateString.split("/").map(Number);
      date = new Date(year, month - 1, day);
    } else {
      return dateString; // Se non è un formato riconosciuto, ritorna la stringa originale
    }

    // Converte la data in formato 'DD/MM/YYYY'
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const getStructureInfo = async (uuid_pass) => {
    setStructureLoading(true);
    structureService.getStructureInfo(`${uuid_pass}`).then(
      (result) => {
        result = result.data;
        setStructureInfo(result);
        setStructureLoading(false);
      },
      (error) => {}
    );
  };
  const getStructureFixedCosts = async (uuid_pass) => {
    structureService.getFixedCosts(`${uuid_pass}`, `${searchBase.lang}`).then(
      (result) => {
        result = result.data;
        setFixedCosts(result);
      },
      (error) => {}
    );
  };
  const toISODate = (date) => {
    // Controllo se il formato è già YYYY-MM-DD
    if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
      return date; // Restituisce direttamente la data se già nel formato corretto
    }

    // Altrimenti, supponiamo che sia DD/MM/YYYY e lo convertiamo
    const [day, month, year] = date.split("/").map(Number);
    return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
      2,
      "0"
    )}`;
  };
  const getRooms = async (new_search) => {
    if (searchBase.uuid !== null && searchBase.uuid !== "") {
      let newSearchBase = searchBase;
      toggleDrawer();

      newSearchBase = {
        ...newSearchBase,
        date_from: toISODate(searchBase.date_from),
        date_to: toISODate(searchBase.date_to),
        promo_code: promoCode,
        rooms: searchBase.rooms.map((room) => {
          if (Array.isArray(room.childs)) room.childs = room.childs.length;
          else room.childs = parseInt(room.childs);
          return room;
        }),
        lang: language,
      };
      setSearchBase(newSearchBase);

      var lastIndexVar = !new_search ? lastIndex.lastIndex : 0;
      if (lastIndexVar === 0) setSearching(true);
      else setIsLoadingMoreRooms(true);

      structureService
        .getRooms(`${newSearchBase.uuid}/${lastIndexVar}`, newSearchBase)
        .then(
          (result) => {
            result = result.data;
            var oldRooms = new_search ? [] : rooms;
            result.map((res) => {
              oldRooms.push(res);
            });
            setRooms(oldRooms);

            if (result.length > 0) {
              setLastIndex({
                lastIndex: result[0].lastIndex,
                has_more_rooms: result[0].has_more_rooms,
                do_new_search: false,
              });
            }
            setIsLoadingMoreRooms(false);
            setSearching(false);
            fetchTax();
          },
          (error) => {
            setIsLoadingMoreRooms(false);
            setSearching(false);
          }
        );
    }
  };
  const fetchTax = async () => {
    if (searchBase.date_from && searchBase.date_to) {
      var date_1 = searchBase.date_from;
      var date_2 = searchBase.date_to;

      if (date_1.includes("-")) {
        date_1 = moment(date_1, "MM-DD-YYYY").format("DD/MM/YYYY");
        date_2 = moment(date_2, "MM-DD-YYYY").format("DD/MM/YYYY");
      }

      var search_tax = {
        date_from: toISODate(searchBase.date_from),
        date_to: toISODate(searchBase.date_to),
      };
      structureService.getStructureTax(searchBase.uuid, search_tax).then(
        (result) => {
          result = result.data;
          setTax(result);
        },
        (error) => {}
      );
    }
  };
  const handlePromoCodeChange = (e) => {
    setPromoCode(e.target.value);
  };

  // function addCart(room, goto_payment, isRefundable) {
  //   // controllo se la stessa configurazione non è già selezionata per le stesse date
  //   var rooms_to_check = [];
  //   const current_cart_0 = JSON.parse(localStorage.getItem("cart"));
  //   console.log(current_cart_0);
  //   if (current_cart_0 && current_cart_0.length > 0) {
  //     current_cart_0.map((item, index) => {
  //       rooms_to_check.push({
  //         date_from: item.start_date,
  //         date_to: item.end_date,
  //         id_category: item.id_category,
  //         uuid: searchBase.uuid,
  //       });
  //     });
  //   }
  //   rooms_to_check.push({
  //     date_from: searchBase.date_from,
  //     date_to: searchBase.date_to,
  //     id_category: room.id_category,
  //     uuid: searchBase.uuid,
  //   });
  //   structureService
  //     .checkRoomAvailability(`${searchBase.uuid}`, rooms_to_check)
  //     .then(
  //       (result) => {
  //         result = result.data;
  //         if (result.success) {
  //           if (result.model) {
  //             if (goto_payment === false) {
  //               Swal.fire({
  //                 title: GetLabel(language, "LABEL_TITLE_ROOM_ADDED_CART"),
  //                 imageUrl:
  //                   "https://cdn.dribbble.com/users/1465772/screenshots/5726748/____dri.gif",
  //                 imageWidth: 290,
  //                 imageHeight: 200,
  //                 text: GetLabel(language, "LABEL_ROOM_ADDED_CART"),
  //                 confirmButtonText: "Ok",
  //               });
  //             }
  //             room.id = makeid(8);
  //             room.start_date = searchBase.date_from;
  //             room.end_date = searchBase.date_to;
  //             room.uuid = searchBase.uuid;

  //             // if (isRefundable) {
  //             //   room.isNotRefundable = true;
  //             // } else room.isNotRefundable = false;

  //             if (goto_payment && searchBase.rooms.length === 1) {
  //               setCart([room]);
  //               localStorage.removeItem("cart");
  //             } else setCart([...cart, room]);

  //             var new_cart = JSON.parse(localStorage.getItem("cart"));
  //             if (new_cart) new_cart.push(room);
  //             else {
  //               new_cart = [];
  //               new_cart.push(room);
  //             }
  //             localStorage.setItem("cart", JSON.stringify(new_cart));

  //             setTotal(parseFloat(total) + parseFloat(room.price));

  //             if (goto_payment) CreateTempBooking(isRefundable);
  //           } else {
  //             Swal.fire({
  //               title: GetLabel(language, "LABEL_ERROR_ALERT_1"),
  //               icon: "error",
  //               imageWidth: 290,
  //               imageHeight: 200,
  //               text: GetLabel(language, "LABEL_BODY_ERROR_ALERT_1"),
  //               confirmButtonText: "Ok",
  //             });
  //           }
  //         }
  //       },
  //       (error) => {}
  //     );
  // }
  function addCart(room, option, goto_payment, isRefundable) {
    // controllo se la stessa configurazione non è già selezionata per le stesse date
    var rooms_to_check = [];
    const current_cart_0 = JSON.parse(localStorage.getItem("cart"));

    if (current_cart_0 && current_cart_0.length > 0) {
      current_cart_0.map((item, index) => {
        rooms_to_check.push({
          date_from: item.start_date,
          date_to: item.end_date,
          id_category: item.id_category,
          uuid: searchBase.uuid,
        });
      });
    }
    rooms_to_check.push({
      date_from: searchBase.date_from,
      date_to: searchBase.date_to,
      id_category: room.id_category,
      uuid: searchBase.uuid,
    });
    structureService
      .checkRoomAvailability(`${searchBase.uuid}`, rooms_to_check)
      .then(
        (result) => {
          result = result.data;
          if (result.success) {
            if (result.model) {
              if (goto_payment === false) {
                Swal.fire({
                  title: GetLabel(language, "LABEL_TITLE_ROOM_ADDED_CART"),
                  imageUrl:
                    "https://cdn.dribbble.com/users/1465772/screenshots/5726748/____dri.gif",
                  imageWidth: 290,
                  imageHeight: 200,
                  text: GetLabel(language, "LABEL_ROOM_ADDED_CART"),
                  confirmButtonText: "Ok",
                });
              }
              const fixedCostsWithDetails = fixedCosts.map((cost) => {
                let costToAdd = parseFloat(cost.price_unit || 0);

                // Se il costo è giornaliero, calcola il numero di notti
                if (cost.is_daily) {
                  const numberOfNights = getNumberOfNights(
                    searchBase.date_from,
                    searchBase.date_to
                  );
                  costToAdd *= numberOfNights; // Moltiplica per il numero di notti
                }

                // Aggiungi un campo per il costo calcolato
                return {
                  name: cost.name,
                  description: cost.description,
                  calculatedPrice: costToAdd, // Calcolo del prezzo
                };
              });
              const fixedCostsTotal = fixedCostsWithDetails.reduce(
                (total, cost) => total + cost.calculatedPrice,
                0
              );

              const newCartItem = {
                id: makeid(8),
                start_date: searchBase.date_from,
                end_date: searchBase.date_to,
                childs: option.children,
                guests: option.adults,
                description: room.description,
                roomName: option.optionName,
                price: option.price,
                id_category: room.id_category,
                id_derived: option.id_derivate, // Aggiungi id_derived da 'room'
                isNotRefundable: option.isNotRefundable,
                policy: room.policy,
                fixedCosts: fixedCostsWithDetails,
                uuid: searchBase.uuid,
              };

              // if (goto_payment && searchBase.rooms.length === 1) {
              //   setCart([newCartItem]);
              //   localStorage.removeItem("cart");
              // } else
              setCart([...cart, newCartItem]);

              var new_cart = JSON.parse(localStorage.getItem("cart"));
              if (new_cart) new_cart.push(newCartItem);
              else {
                new_cart = [];
                new_cart.push(newCartItem);
              }
              localStorage.setItem("cart", JSON.stringify(new_cart));
              setTotal(
                (total) =>
                  parseFloat(total) + fixedCostsTotal + parseFloat(option.price)
              );
              // setTotal(parseFloat(total) + parseFloat(option.price));

              if (goto_payment) CreateTempBooking(isRefundable);
            } else {
              Swal.fire({
                title: GetLabel(language, "LABEL_ERROR_ALERT_1"),
                icon: "error",
                imageWidth: 290,
                imageHeight: 200,
                text: GetLabel(language, "LABEL_BODY_ERROR_ALERT_1"),
                confirmButtonText: "Ok",
              });
            }
          }
        },
        (error) => {}
      );
  }
  const getNumberOfNights = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const timeDiff = end - start;
    const days = timeDiff / (1000 * 3600 * 24); // Differenza in giorni
    return days >= 0 ? days : 0; // Se la differenza è negativa (errori nelle date), ritorna 0
  };
  const removeItemCart = (item) => {
    var new_cart = JSON.parse(localStorage.getItem("cart"));
    new_cart = new_cart.filter((el) => el.id !== item.id);
    setCart(cart.filter((el) => el.id !== item.id));
    localStorage.setItem("cart", JSON.stringify(new_cart));
    const fixedCostsTotal = item.fixedCosts.reduce(
      (total, cost) => total + cost.calculatedPrice,
      0
    );

    setTotal(parseFloat(total) - parseFloat(item.price) - fixedCostsTotal);
  };

  const CreateTempBooking = (isRefundable) => {
    setIsLoading(true);
    const cart = JSON.parse(localStorage.getItem("cart"));
    const totalCartGuests = cart.reduce(
      (acc, item) =>
        acc + (parseInt(item.guests) || 0) + (parseInt(item.childs) || 0),
      0
    );
    const totalSearchBaseGuests = searchBase.rooms.reduce(
      (acc, room) =>
        acc + (parseInt(room.adults) || 0) + (parseInt(room.childs) || 0),
      0
    );
    const isCartUnderOccupied = totalCartGuests < totalSearchBaseGuests;
    if (isCartUnderOccupied) {
      Swal.fire({
        title: GetLabel(language, "LABLE_OCCUPAZIONE_INFERIORE_TITLE"),
        text: GetLabel(language, "LABLE_OCCUPAZIONE_INFERIORE_DESCR"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: GetLabel(
          language,
          "LABLE_OCCUPAZIONE_INFERIORE_YES"
        ),
        cancelButtonText: GetLabel(language, "LABEL_CHECK_ROOM_CART_NO"),
      }).then((result) => {
        if (result.isConfirmed) {
          var save_cart = JSON.parse(localStorage.getItem("cart"));
          if (save_cart) {
            save_cart = save_cart.map((item, index) => {
              item.date_from = item.start_date;
              item.date_to = item.end_date;
              item.discount_code = searchBase.promo_code;
              item.uuid = searchBase.uuid;

              return item;
            });

            let canRefund = isRefundable ? "/true" : "";

            structureService
              .createTempBooking(
                searchBase.uuid + "/" + language + canRefund,
                save_cart
              )
              .then(
                (result) => {
                  result = result.data;
                  if (result.success === true)
                    navigate(
                      "/pay/" +
                        searchBase.uuid +
                        "/" +
                        result.model.booking.link
                    );
                  else {
                    setIsLoading(false);
                  }
                },
                (error) => {}
              );
          } else {
          }
        }
      });
      setIsLoading(false);
    } else {
      var save_cart = JSON.parse(localStorage.getItem("cart"));
      if (save_cart) {
        save_cart = save_cart.map((item, index) => {
          item.date_from = item.start_date;
          item.date_to = item.end_date;
          item.discount_code = searchBase.promo_code;
          item.uuid = searchBase.uuid;

          return item;
        });

        let canRefund = isRefundable ? "/true" : "";

        structureService
          .createTempBooking(
            searchBase.uuid + "/" + language + canRefund,
            save_cart
          )
          .then(
            (result) => {
              result = result.data;
              if (result.success === true)
                navigate(
                  "/pay/" + searchBase.uuid + "/" + result.model.booking.link
                );
              else {
                setIsLoading(false);
              }
            },
            (error) => {}
          );
      } else {
      }
    }
  };

  const openCart = () => {
    setIsOpenCart(true);
    setIsLoadingCart(true);
    var current_cart = JSON.parse(localStorage.getItem("cart"));
    if (current_cart.length > 0) {
      current_cart.map((item, index) => {
        CheckAvailability(
          item,
          item.start_date,
          item.end_date,
          item.id_category,
          index,
          current_cart.length
        );
      });
    } else setIsLoadingCart(false);
  };

  const CheckAvailability = (
    item,
    start_date,
    end_date,
    id_category,
    index,
    max
  ) => {
    var obj_category = {
      id_category: id_category,
      date_from: start_date,
      date_to: end_date,
    };
    structureService.checkAvailability(searchBase.uuid, obj_category).then(
      (result) => {
        result = result.data;
        if (result.success === true) {
          var is_available = result.model;
          var current_cart = JSON.parse(localStorage.getItem("cart"));
          if (!is_available) {
            removeItemCart(item);
            setRemovedItems([...removedItems, item]);
          }
          if (index === max - 1) setIsLoadingCart(false);
        } else {
          setIsLoading(false);
        }
      },
      (error) => {}
    );
  };

  const handleDrawerClose = () => {
    //setRemovedItems([])
    setIsOpenCart(false);
  };
  const isMobile = useMediaQuery("(max-width:767px)");
  const [selectedDate, setSelectedDate] = React.useState(dates);
  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
    setDates(newValue); // Aggiorna le date nel componente principale
  };
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <div
      className="bg-body"
      style={{
        background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('${structureInfo.structure_background}')`,
        minHeight: "100vh",
      }}
    >
      <Drawer
        anchor={"right"}
        open={isOpenCart}
        onClose={handleDrawerClose}
        sx={{
          "& .MuiDrawer-paper": {
            xs: {
              width: "100%",
            },
            sm: {
              width: "500px",
            },
          },
        }}
      >
        {isLoadingCart ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "10px",
                paddingBottom: "10px",
                borderBottom: "1px solid #E2E8F0",
              }}
            >
              <h3 style={{ margin: 0, marginLeft: "20px" }}>
                {GetLabel(language, "LABEL_YOUR_CART")}
              </h3>
              <IconButton
                sx={{
                  marginLeft: "auto",
                  height: "40px",
                  marginRight: "20px",
                }}
                onClick={handleDrawerClose}
              >
                <DynamicHeroIcon
                  icon={"XIcon"}
                  className="input-icon"
                ></DynamicHeroIcon>
              </IconButton>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
              }}
            >
              <div
                style={{
                  padding: "15px",
                  paddingBottom: "0px !important",
                  paddingRight: "15px !important",
                }}
              >
                {removedItems.length > 0 && (
                  <>
                    {removedItems.map((item) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: "20px",
                            marginTop: "0",
                            border: "1px solid red",
                            borderRadius: "10px",
                            backgroundColor: "rgb(255 239 239)",
                            padding: "10px",
                          }}
                        >
                          <DynamicHeroIcon
                            icon="ExclamationIcon"
                            style={{
                              width: "48px",
                              height: "48px",
                              color: "red",
                            }}
                          ></DynamicHeroIcon>
                          <p
                            style={{
                              marginTop: "0",
                              marginBottom: "0",
                              color: "#c92c2c",
                              marginLeft: "10px",
                            }}
                          >
                            {GetLabel(language, "LABEL_ROOM_REMOVED_APARTMENT")}
                            <b>{item.roomName}</b>{" "}
                            {GetLabel(language, "LABEL_FROM_DAY")}{" "}
                            <b>{item.start_date}</b>{" "}
                            {GetLabel(language, "LABEL_TO_DAY")}{" "}
                            <b>{item.end_date + " "}</b>
                            {GetLabel(language, "LABEL_ROOM_REMOVED")}
                          </p>
                        </div>
                      );
                    })}
                  </>
                )}
                {JSON.parse(localStorage.getItem("cart")) &&
                JSON.parse(localStorage.getItem("cart")).length > 0 ? (
                  JSON.parse(localStorage.getItem("cart")).map(
                    (item, index) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "#f2f2f2",
                          borderRadius: "8px",
                          padding: "15px",
                          marginBottom: "20px",
                          minHeight: "100px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div style={{ width: "150px" }}>
                            {item.images && item.images.length > 0 && (
                              <Flickity options={{ lazyLoad: true }}>
                                {item.images.map((image, i) => {
                                  return (
                                    <div
                                      style={{
                                        background: "url(" + image.image + ")",
                                      }}
                                      className="carousel-img-cart"
                                    ></div>
                                  );
                                })}
                              </Flickity>
                            )}
                          </div>
                          <div style={{ width: "auto", marginLeft: "20px" }}>
                            <h3 style={{ margin: 0 }}>{item.roomName}</h3>
                            <p style={{ margin: 0, color: "rgb(169 169 169)" }}>
                              {item.tabName}
                            </p>
                            <p style={{ margin: 0, marginTop: "10px" }}>
                              {GetLabel(language, "LABEL_FROM_DAY_SHORT")}{" "}
                              {item.start_date}{" "}
                              {GetLabel(language, "LABEL_TO_DAY")}{" "}
                              {item.end_date}
                            </p>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <Tooltip
                                title={GetLabel(
                                  language,
                                  "LABEL_SEARCH_ADULTI"
                                )}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginRight: "20px",
                                    color: "rgb(102 102 102)",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={"male"}
                                    className="recap-icon"
                                  />
                                  <p
                                    className="adults"
                                    style={{ marginLeft: "5px" }}
                                  >
                                    {" "}
                                    x {item.guests}
                                  </p>
                                </div>
                              </Tooltip>
                              <Tooltip
                                title={GetLabel(
                                  language,
                                  "LABEL_SEARCH_BAMBINI"
                                )}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginRight: "20px",
                                    color: "rgb(102 102 102)",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={"child"}
                                    className="recap-icon-small"
                                  />
                                  <p
                                    className="adults"
                                    style={{ marginLeft: "5px" }}
                                  >
                                    {" "}
                                    x {item.childs}
                                  </p>
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "30px",
                              marginLeft: "auto",
                              marginTop: "-10px",
                            }}
                          >
                            <Tooltip
                              title={GetLabel(
                                language,
                                "LABEL_REMOVE_FROM_CART"
                              )}
                            >
                              <IconButton
                                sx={{ marginLeft: "auto" }}
                                onClick={() => removeItemCart(item)}
                              >
                                <DynamicHeroIcon
                                  icon={"TrashIcon"}
                                  className="input-icon"
                                ></DynamicHeroIcon>
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                          }}
                        >
                          <h2 style={{ margin: 0, marginTop: "-20px" }}>
                            € {parseFloat(item.price).toFixed(2)}
                          </h2>
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <DynamicHeroIcon
                      icon={"SparklesIcon"}
                      className="input-icon"
                      style={{ width: "40px", height: "40px" }}
                    ></DynamicHeroIcon>
                    <p
                      style={{
                        margin: 0,
                        marginTop: "10px",
                        fontSize: "20px",
                        width: "100%",
                        textAlign: "center",
                      }}
                      className="input-icon"
                    >
                      {GetLabel(language, "LABEL_OPS_EMPTY")}
                    </p>
                    <p
                      style={{
                        margin: 0,
                        marginTop: "10px",
                        fontSize: "14px",
                        width: "100%",
                        textAlign: "center",
                      }}
                      className="input-icon"
                    >
                      {GetLabel(language, "LABEL_ADD_ROOM_PLACEHOLDER")}
                    </p>
                  </div>
                )}
              </div>
              <div
                style={{
                  marginTop: "auto",
                  backgroundColor: "#F1F5F9",
                  padding: "16px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h4 style={{ margin: 0, fontSize: "25px", textAlign: "right" }}>
                  <span
                    style={{
                      fontWeight: "normal",
                      marginRight: "20px",
                      fontSize: "22px",
                    }}
                  >
                    {GetLabel(language, "LABEL_TOTAL")}
                  </span>{" "}
                  € {parseFloat(total).toFixed(2)}
                </h4>

                {total > 0 && (
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    sx={{
                      width: "250px",
                      fontSize: "14px !important",
                      fontWeight: "500 !important",
                      marginLeft: "auto",
                      marginTop: "15px",
                    }}
                    className="btn-primary"
                    loading={isLoading}
                    onClick={CreateTempBooking}
                    loadingPosition="start"
                    color="primary"
                  >
                    {GetLabel(language, "LABEL_GOTO_PAYMENT")}
                    <DynamicHeroIcon
                      icon={"ArrowRightIcon"}
                      className="input-icon"
                      style={{ color: "#FFF", marginLeft: "5px" }}
                    ></DynamicHeroIcon>
                  </LoadingButton>
                )}
              </div>
            </div>
          </>
        )}
      </Drawer>
      <HorizontalBar
        structure_name={structureInfo.structure_name}
        structure_address={structureInfo.structure_address}
        structure_location={structureInfo.structure_location}
        structure_logo={structureInfo.structure_logo}
        structure_phone={structureInfo.structure_phone}
        structure_email={structureInfo.structure_email}
        isStructureLoading={isStructureLoading}
        uuid={structureInfo.uuid}
        key={structureInfo.uuid}
        setLanguage={setLanguage}
        language={language}
      ></HorizontalBar>

      <Grid
        container
        spacing={2}
        sx={{ padding: "10px", marginTop: { xs: "60px", md: "0" } }}
      >
        <Grid
          item
          xl={3}
          md={3}
          xs={12}
          sx={
            {
              // position: { xs: "auto", md: "sticky" }, // 'relative' su mobile, 'sticky' su desktop
              // // 'auto' su mobile, '0' su desktop per mantenere fisso
              // height: { xs: "auto", md: "100vh" }, // Altezza completa su desktop, auto su mobile
              // overflowY: { xs: "auto", md: "hidden" }, // Scroll su mobile, no scroll su desktop
            }
          }
        >
          {isLoading ? (
            <CircularProgress />
          ) : isMobile ? (
            <div>
              <Box>
                <Card
                  style={{ margin: "0", marginBottom: "10px" }}
                  sx={{ margin: "1rem" }}
                  onClick={toggleDrawer}
                >
                  <CardContent>
                    <Typography
                      gutterBottom
                      component="div"
                      style={{
                        fontSize: "18px !important",
                        fontWeight: "600 !important",
                        color: "rgb(44 44 44) !important",
                      }}
                    >
                      Modifica date
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Date: {formatDate(searchBase.date_from)} -{" "}
                      {formatDate(searchBase.date_to)}
                    </Typography>
                  </CardContent>
                </Card>

                <Drawer
                  anchor="right"
                  open={openDrawer}
                  onClose={toggleDrawer}
                  sx={{
                    "& .MuiDrawer-paper": {
                      width: "100%",
                      // padding: "20px",
                      display: "flex",
                      flexDirection: "column",
                    },
                  }}
                  style={{
                    zIndex: "9999",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "20px",
                    }}
                  >
                    <Typography variant="h6">Modifica ricerca</Typography>
                    <CloseIcon onClick={toggleDrawer}></CloseIcon>
                  </Box>
                  <MyCalendar
                    uuid={structureInfo.uuid}
                    getRooms={getRooms}
                    isSearching={isSearching}
                    promoCode={promoCode}
                    handlePromoCodeChange={handlePromoCodeChange}
                    language={language}
                    searchBase={searchBase}
                    setSearchBase={setSearchBase}
                  ></MyCalendar>
                </Drawer>
              </Box>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#fff",
                borderRadius: "5px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                padding: "20px",
                overflow: "hidden",
              }}
            >
              <MyCalendar
                uuid={structureInfo.uuid}
                getRooms={getRooms}
                isSearching={isSearching}
                promoCode={promoCode}
                handlePromoCodeChange={handlePromoCodeChange}
                language={language}
                searchBase={searchBase}
                setSearchBase={setSearchBase}
              ></MyCalendar>
            </div>
          )}
        </Grid>
        <Grid
          item
          xl={6}
          md={6}
          xs={12}
          className="room-container"
          sx={{
            top: { xs: "100px", md: 0 }, // 'auto' su mobile, '0' su desktop per mantenere fisso
            height: "100vh",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              display: "none", // Nasconde la scrollbar nei browser WebKit
            },
            scrollbarWidth: "none", // Nasconde la scrollbar in Firefox
            "-ms-overflow-style": "none", // Nasconde la scrollbar in IE e Edge Legacy
            paddingBottom: { xs: "150px", md: 0 },
          }}
        >
          {isSearching === false ? (
            <div className="flex flex-col gap-2">
              <Packets language={language} uuid={structureInfo.uuid} />
              {rooms.length === 1 && rooms[0].gg_min !== null ? (
                <AlertAvailability
                  phone={structureInfo.structure_phone}
                  email={structureInfo.structure_email}
                  text={
                    GetLabel(language, "LABEL_NO_ROOMS_GG_MIN_1") +
                    rooms[0].gg_min +
                    GetLabel(language, "LABEL_NO_ROOMS_GG_MIN_2")
                  }
                  language={language}
                />
              ) : rooms.length === 1 && rooms[0].isOverLastMinute ? (
                <AlertAvailability
                  phone={structureInfo.structure_phone}
                  email={structureInfo.structure_email}
                  label="LABEL_NO_ROOMS_LAST_MINUTE"
                  language={language}
                />
              ) : rooms.length > 0 ? (
                rooms.map((room_group, index_room) => {
                  return (
                    <Room
                      crooms={rooms}
                      rooms={room_group.rooms}
                      setRooms={setRooms}
                      isSearching={isSearching}
                      setSearching={setSearching}
                      uuid={structureInfo.uuid}
                      addCart={addCart}
                      tax={tax}
                      language={language}
                      nrooms={searchBase.rooms.length}
                      idCategory={room_group.id_category}
                      index_room={index_room}
                    ></Room>
                  );
                })
              ) : (
                <AlertAvailability
                  phone={structureInfo.structure_phone}
                  email={structureInfo.structure_email}
                  label="LABEL_NO_ROOMS_FOUND"
                  language={language}
                />
              )}
            </div>
          ) : (
            <>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "20px",
                  }}
                >
                  <CircularProgress sx={{ color: "#FFF" }} />
                </Grid>
              </Grid>
              <Card sx={{ borderRadius: "10px", marginBottom: "20px" }}>
                <CardContent>
                  <SkeletonRoom></SkeletonRoom>
                </CardContent>
              </Card>
              <Card sx={{ borderRadius: "10px", marginBottom: "20px" }}>
                <CardContent>
                  <SkeletonRoom></SkeletonRoom>
                </CardContent>
              </Card>
              <Card sx={{ borderRadius: "10px", marginBottom: "20px" }}>
                <CardContent>
                  <SkeletonRoom></SkeletonRoom>
                </CardContent>
              </Card>
              <Card sx={{ borderRadius: "10px", marginBottom: "20px" }}>
                <CardContent>
                  <SkeletonRoom></SkeletonRoom>
                </CardContent>
              </Card>
            </>
          )}

          {isLoadingMoreRooms ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <CircularProgress sx={{ color: "#FFF" }} />
            </div>
          ) : (
            <>
              {lastIndex.lastIndex % 5 === 0 &&
                lastIndex.has_more_rooms &&
                !isSearching && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setLastIndex({ ...lastIndex, do_new_search: true });
                    }}
                  >
                    <div
                      style={{
                        padding: "15px",
                        backgroundColor: "white",
                        color: "#000",
                        width: "auto",
                        borderRadius: "10px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        class="w-5 h-5"
                        style={{ color: "#444444" }}
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                      Mostra di più
                    </div>
                  </div>
                )}
            </>
          )}
        </Grid>
        <Grid
          item
          xl={3}
          md={3}
          xs={12}
          sx={{
            position: "sticky",
            top: 0,
            height: "100vh",
            overflow: "auto",
            display: { xs: "none", md: "block" }, // Nascosto su mobile
          }}
        >
          {JSON.parse(localStorage.getItem("cart"))?.length > 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#fff",
                borderRadius: "5px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                padding: "20px",
                overflow: "hidden",
              }}
            >
              <h2 style={{ fontSize: "20px", fontWeight: "500" }}>
                {GetLabel(language, "LABEL_RIEPILOGO")}
              </h2>

              <div style={{ marginBottom: "15px" }}>
                {removedItems.length > 0 &&
                  removedItems.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: "10px",
                        paddingTop: "10px",
                      }}
                    >
                      <DynamicHeroIcon
                        icon="ExclamationIcon"
                        style={{
                          width: "24px",
                          height: "24px",
                          color: "#d32f2f",
                          marginRight: "8px",
                        }}
                      />
                      <p
                        style={{
                          color: "#d32f2f",
                          margin: 0,
                          lineHeight: "1.5",
                        }}
                      >
                        {GetLabel(language, "LABEL_ROOM_REMOVED_APARTMENT")}
                        <b>{item.roomName}</b>{" "}
                        {GetLabel(language, "LABEL_FROM_DAY")}{" "}
                        <b>{formatDate(item.start_date)}</b>{" "}
                        {GetLabel(language, "LABEL_TO_DAY")}{" "}
                        <b>{formatDate(item.end_date)}</b>{" "}
                        {GetLabel(language, "LABEL_ROOM_REMOVED")}
                      </p>
                    </div>
                  ))}
              </div>
              <div
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  marginBottom: "15px",
                }}
              >
                {JSON.parse(localStorage.getItem("cart")).map((item, index) => (
                  <div
                    style={{
                      marginBottom: "8px",
                    }}
                  >
                    <div className="flex flex-row">
                      <h4
                        style={{
                          margin: "0",
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#333",
                        }}
                      >
                        {item.roomName}
                      </h4>
                      <div
                        style={{
                          width: "30px",
                          marginLeft: "auto",
                          marginTop: "-8px",
                        }}
                      >
                        <Tooltip
                          title={GetLabel(language, "LABEL_REMOVE_FROM_CART")}
                        >
                          <IconButton
                            sx={{ marginLeft: "auto" }}
                            onClick={() => removeItemCart(item)}
                          >
                            <DynamicHeroIcon
                              icon={"TrashIcon"}
                              className="trash-icon"
                            ></DynamicHeroIcon>
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: "8px",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <p
                          style={{
                            margin: "0",
                            color: "grey",
                            fontSize: "13px",
                            display: "flex",
                            gap: "10px",
                          }}
                        >
                          {/* Dettagli ospiti e bambini */}
                          <Tooltip
                            title={GetLabel(language, "LABEL_SEARCH_ADULTI")}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: "grey",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={"male"}
                                style={{ marginRight: "5px", fontSize: "13px" }}
                              />
                              <span style={{ fontSize: "13px" }}>
                                x {item.guests}
                              </span>
                            </div>
                          </Tooltip>
                          <Tooltip
                            title={GetLabel(language, "LABEL_SEARCH_BAMBINI")}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: "grey",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={"child"}
                                style={{ marginRight: "5px", fontSize: "13px" }}
                              />
                              <span style={{ fontSize: "13px" }}>
                                x {item.childs}
                              </span>
                            </div>
                          </Tooltip>
                          {item.isNotRefundable && (
                            <div className="w-fit text-xs py-1 px-3 rounded-full bg-purple-500 text-white font-semibold">
                              {GetLabel(language, "LABEL_NOREFUNDABLE")}
                            </div>
                          )}
                        </p>

                        <p
                          style={{
                            margin: "5px 0 0",
                            fontSize: "12px",
                            color: "#555",
                          }}
                        >
                          {GetLabel(language, "LABEL_FROM_DAY_SHORT")}{" "}
                          {formatDate(item.start_date)} -{" "}
                          {GetLabel(language, "LABEL_TO_DAY")}{" "}
                          {formatDate(item.end_date)}
                        </p>
                        {/* <p
                          style={{
                            margin: "5px 0 0",
                            fontSize: "12px",
                            color: "#555",
                            whiteSpace: "nowrap", // Impedisce il testo di andare a capo
                            overflow: "hidden", // Nasconde il testo oltre i limiti
                            textOverflow: "ellipsis", // Aggiunge i puntini di sospensione
                            maxWidth: "300px",
                          }}
                          dangerouslySetInnerHTML={{ __html: item.description }} // Permette l'HTML
                        ></p> */}
                      </div>
                      <h2
                        style={{
                          margin: "0",
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#333",
                          textAlign: "right",
                        }}
                      >
                        € {parseFloat(item.price).toFixed(2)}
                      </h2>
                    </div>
                    {item.fixedCosts &&
                      item.fixedCosts.map((cost, costIndex) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <h4
                              style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "#333",
                              }}
                            >
                              {cost.name}
                            </h4>
                            <p
                              style={{
                                margin: "0 0 10px",
                                fontSize: "12px",
                                color: "#555",
                                maxWidth: "336px",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: cost.description,
                              }}
                            ></p>
                          </div>
                          <h2
                            style={{
                              margin: "0",
                              fontSize: "16px",
                              fontWeight: "bold",
                              color: "#333",
                            }}
                          >
                            € {parseFloat(cost.calculatedPrice).toFixed(2)}
                          </h2>
                        </div>
                      ))}
                    {item.isNotRefundable && (
                      <p
                        className=" text-gray-600"
                        style={{
                          fontSize: "12px",
                          paddingBottom: "8px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                        >
                          {GetLabel(
                            language,
                            "TITLE_CANCELLATION_POLICY_NOREFUNDABLE"
                          )}
                        </span>
                        : <br></br>{" "}
                        {GetLabel(
                          language,
                          "LABEL_CANCELLATION_POLICY_NOREFUNDABLE"
                        )}
                      </p>
                    )}
                  </div>
                ))}
              </div>

              <div
                style={{
                  borderTop: "1px solid #ddd",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h4
                  style={{
                    fontSize: "22px",
                    margin: 0,
                    color: "#333",
                    paddingTop: "16px",
                  }}
                >
                  <span style={{ fontWeight: "normal", fontSize: "18px" }}>
                    {GetLabel(language, "LABEL_TOTAL")}
                  </span>{" "}
                  <b>€ {parseFloat(total).toFixed(2)}</b>
                </h4>

                {total > 0 && (
                  <LoadingButton
                    variant="contained"
                    sx={{
                      mt: 2,
                      maxWidth: "250px",
                      fontWeight: "600",
                    }}
                    style={{
                      marginTop: "0px !important",
                      paddingTop: "0px !important",
                      paddingBottom: "0px !important",
                      fontSize: "15px !important",
                    }}
                    className="btn-primary"
                    loading={isLoading}
                    onClick={CreateTempBooking}
                    loadingPosition="start"
                    color="primary"
                  >
                    {GetLabel(language, "LABEL_GOTO_PAYMENT")}
                    <DynamicHeroIcon
                      icon="ArrowRightIcon"
                      style={{
                        color: "#FFF",
                        marginLeft: "5px",
                        width: "24px",
                        height: "24px",
                      }}
                    />
                  </LoadingButton>
                )}
              </div>
            </div>
          ) : null}{" "}
        </Grid>
        <Box
          sx={{
            display: { xs: "flex", md: "none" }, // Visibile solo su mobile
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <MobileCart
            cartItems={JSON.parse(localStorage.getItem("cart"))}
            total={total}
            isLoading={false}
            CreateTempBooking={CreateTempBooking}
            formatDate={formatDate}
            language={language}
            removeItemCart={removeItemCart}
            fixedCosts={fixedCosts}
          ></MobileCart>
        </Box>
      </Grid>

      {/* Icona carrello in basso a destra */}
      {/* {(searchBase.rooms.length > 1 ||
        (JSON.parse(localStorage.getItem("cart")) &&
          JSON.parse(localStorage.getItem("cart")).length > 0)) && (
        <Fab
          color="primary"
          aria-label="add"
          className="cart-fav"
          onClick={openCart}
        >
          <Badge
            badgeContent={
              JSON.parse(localStorage.getItem("cart"))
                ? JSON.parse(localStorage.getItem("cart")).length
                : 0
            }
            color="error"
          >
            <DynamicHeroIcon
              icon={"ShoppingCartIcon"}
              className="cart-icon"
            ></DynamicHeroIcon>
          </Badge>
        </Fab>
      )} */}
    </div>
  );
}
