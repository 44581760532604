import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export default function LanguageDrawer(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const setLanguage = (lang) => {
    props.setLanguage(lang);
    handleClose();
  };
  const languageNames = {
    IT: "Italiano",
    EN: "English",
    FR: "Français",
    DE: "Deutsch",
    ES: "Español",
  };

  React.useEffect(() => {
    console.log(props.language);
  }, [props.language]);

  return (
    <div className={props.className}>
      <Button
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <img
          src={`https://www.idaweb.eu/Images/Sys/flag/${props.language}.png`}
          className="flag-selected"
          style={{ width: "20px", height: "20px" }}
        ></img>

        <p style={{ fontSize: "12px" }}>
          {languageNames[props.language] || props.language}
        </p>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={() => setLanguage("IT")}>
          <img
            src="https://www.idaweb.eu/Images/Sys/flag/IT.png"
            className="flag-select"
            style={{ width: "20px", height: "20px" }}
          ></img>
          <p style={{ fontSize: "12px" }}>{languageNames["IT"]}</p>
        </MenuItem>
        <MenuItem onClick={() => setLanguage("EN")}>
          <img
            src="https://www.idaweb.eu/Images/Sys/flag/EN.png"
            className="flag-select"
            style={{ width: "20px", height: "20px" }}
          ></img>
          <p style={{ fontSize: "12px" }}>{languageNames["EN"]}</p>
        </MenuItem>
        <MenuItem onClick={() => setLanguage("DE")}>
          <img
            src="https://www.idaweb.eu/Images/Sys/flag/DE.png"
            className="flag-select"
            style={{ width: "20px", height: "20px" }}
          ></img>
          <p style={{ fontSize: "12px" }}>{languageNames["DE"]}</p>
        </MenuItem>
        <MenuItem onClick={() => setLanguage("FR")}>
          <img
            src="https://www.idaweb.eu/Images/Sys/flag/FR.png"
            className="flag-select"
            style={{ width: "20px", height: "20px" }}
          ></img>
          <p style={{ fontSize: "12px" }}>{languageNames["FR"]}</p>
        </MenuItem>
        <MenuItem onClick={() => setLanguage("ES")}>
          <img
            src="https://www.idaweb.eu/Images/Sys/flag/SP.png"
            className="flag-select"
            style={{ width: "20px", height: "20px" }}
          ></img>
          <p style={{ fontSize: "12px" }}>{languageNames["ES"]}</p>
        </MenuItem>
      </Menu>
    </div>
  );
}
