import * as React from "react";
import { GetLabel } from "../../languages/i18n";
import { Typography, Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid2 from "@mui/material/Unstable_Grid2";

export default function AlertAvailability({
  phone,
  email,
  text,
  label,
  language,
}) {
  return (
    <Card>
      <CardContent>
        <Grid2 container spacing={2} alignItems="center">
          <Grid2
            item
            xs={12}
            sm={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <img
              src="/assets/images/no-calendar.png"
              style={{
                maxWidth: "100%",
                height: "auto",
                borderRadius: "10px",
              }}
              alt="No calendar"
            />
          </Grid2>
          <Grid2
            item
            xs={12}
            sm={10}
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                color: "#333",
                marginBottom: 1,
              }}
            >
              {label !== "" || label !== null
                ? GetLabel(language, label)
                : text}
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#555", lineHeight: "1.6" }}
            >
              {GetLabel(language, "LABEL_CONTACT_US")}
            </Typography>
            <b>Tel</b>:{" "}
            <a
              style={{
                color: "#007bff",
                textDecoration: "none",
              }}
              onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
              onMouseOut={(e) => (e.target.style.textDecoration = "none")}
              href={`tel:${phone}`}
            >
              {phone}
            </a>
            <br />
            <b>Email</b>:{" "}
            <a
              style={{
                color: "#007bff",
                textDecoration: "none",
              }}
              onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
              onMouseOut={(e) => (e.target.style.textDecoration = "none")}
              href={`mailto:${email}`}
            >
              {email}
            </a>
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  );
}
