import { EN_labels } from "./en";
import { IT_labels } from "./it";
import { DE_labels } from "./de";
import { FR_labels } from "./fr";
import { ES_labels } from "./es";

export const GetLabel = (language, label) => {
  var text = "NOT FOUND";
  try {
    switch (language) {
      case "IT":
        if (IT_labels.filter((lbl) => lbl.label === label) !== undefined)
          text = IT_labels.filter((lbl) => lbl.label === label)[0].text;
        else text = "ERR";
        break;
      case "EN":
        if (EN_labels.filter((lbl) => lbl.label === label) !== undefined)
          text = EN_labels.filter((lbl) => lbl.label === label)[0].text;
        else text = "ERR";
        break;
      case "DE":
        if (DE_labels.filter((lbl) => lbl.label === label) !== undefined)
          text = DE_labels.filter((lbl) => lbl.label === label)[0].text;
        else text = "ERR";
        break;
      case "FR":
        if (FR_labels.filter((lbl) => lbl.label === label) !== undefined)
          text = FR_labels.filter((lbl) => lbl.label === label)[0].text;
        else text = "ERR";
        break;
      case "ES":
        if (ES_labels.filter((lbl) => lbl.label === label) !== undefined)
          text = ES_labels.filter((lbl) => lbl.label === label)[0].text;
        else text = "ERR";
        break;
    }
  } catch (err) {
    console.log("ERROR i18n => " + err);
  }
  return text;
};
