import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingButton from "@mui/lab/LoadingButton";
import * as React from "react";
import DynamicHeroIcon from "../../components/heroicons/hicon";
import { useNavigate } from "react-router-dom";
import { GetLabel } from "../../languages/i18n";
import Swal from "sweetalert2";

export default function Summary(props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [language, setLanguage] = React.useState("IT");
  const Swal = require("sweetalert2");

  // const [showConfirmRoomCart, setConfirmRoomCart] = React.useState(false);

  let navigate = useNavigate();

  const goBack = () => {
    Swal.fire({
      title: GetLabel(props.language, "LABEL_CHECK_ROOM_CART"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: GetLabel(props.language, "LABEL_CHECK_ROOM_CART_YES"),
      cancelButtonText: GetLabel(props.language, "LABEL_CHECK_ROOM_CART_NO"),
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        navigate(-1);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        showConfirmRoomCart();
        setIsLoading(true);
        navigate(-1);
      }
    });
  };
  function showConfirmRoomCart() {
    localStorage.removeItem("cart");
  }

  return (
    <>
      <div className="summary-box">
        <div className="summary-box-1">
          <div className="summary-dates">
            <div className="summary-date-left">
              <span className="summary-date-header">
                {GetLabel(props.language, "LABEL_CALENDAR_ARRIVO")}
              </span>
              <div className="summary-date-container">
                <h2>{props.summary.date_from_number}</h2>
                <div className="summary-date-text">
                  <span className="summary-date-day">
                    {props.summary.date_from_day}
                  </span>
                  <span className="summary-date-month">
                    {props.summary.date_from_month}
                  </span>
                  <span className="summary-date-year">
                    {props.summary.date_from_year}
                  </span>
                </div>
              </div>
            </div>
            <div className="summary-date-right">
              <div style={{ marginLeft: "20px" }}>
                <span className="summary-date-header">
                  {GetLabel(props.language, "LABEL_CALENDAR_PARTENZA")}
                </span>
                <div className="summary-date-container">
                  <h2>{props.summary.date_to_number}</h2>
                  <div className="summary-date-text">
                    <span className="summary-date-day">
                      {props.summary.date_to_day}
                    </span>
                    <span className="summary-date-month">
                      {props.summary.date_to_month}
                    </span>
                    <span className="summary-date-year">
                      {props.summary.date_to_year}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="summary-date-arrow">
              <div className="arrow-icon">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/arrow-right.svg"
                  }
                ></img>
              </div>
            </div>
          </div>
          <div className="summary-down-box">
            <div className="summary-down-box-container">
              <FontAwesomeIcon icon={"moon"} className="recap-icon-small" />
              <div className="summary-down-box-text">
                <span className="summary-down-box-text-number">
                  {props.summary.nights}
                </span>
                <span className="summary-down-box-text-label">
                  {GetLabel(props.language, "LABEL_CALENDAR_NOTTI")}
                </span>
              </div>
            </div>
            <div className="summary-down-box-container">
              <FontAwesomeIcon icon={"male"} className="recap-icon-small" />
              <div className="summary-down-box-text">
                <span className="summary-down-box-text-number">
                  {props.summary.n_adults}
                </span>
                <span className="summary-down-box-text-label">
                  {GetLabel(props.language, "LABEL_SEARCH_ADULTI")}
                </span>
              </div>
            </div>
            <div className="summary-down-box-container">
              <FontAwesomeIcon
                icon={"child"}
                className="recap-icon-small"
                style={{
                  fontSize: "20px",
                  marginTop: "5px",
                }}
              />
              <div className="summary-down-box-text">
                <span className="summary-down-box-text-number">
                  {props.summary.n_children}
                </span>
                <span className="summary-down-box-text-label">
                  {GetLabel(props.language, "LABEL_SEARCH_BAMBINI")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingButton
        fullWidth
        variant="contained"
        sx={{
          width: "100%",
          fontSize: "15px !important",
          fontWeight: "500 !important",
          marginLeft: "auto",
          marginTop: "15px",
        }}
        className="btn-primary"
        loading={isLoading}
        loadingPosition="start"
        color="primary"
        onClick={goBack}
      >
        <DynamicHeroIcon
          icon={"ArrowLeftIcon"}
          className="input-icon"
          style={{ color: "#FFF", marginRight: "5px" }}
        ></DynamicHeroIcon>
        {GetLabel(props.language, "LABEL_RETURN_TO_SEARCH")}
      </LoadingButton>
    </>
  );
}
